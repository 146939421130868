<template>
  <section class="broker-login">
    <div class="container">
      <div class="broker-login__wrapper">
        <Breadcrumb v-if="!asComponent" :pages="pages"/>
        <div class="broker-login__body">
          <div class="broker-login__form">
            <button
              v-if="verificationStep === 1"
              class="btn broker-login__back"
              @click="toFirstStep"
            >
              <or-icon type="arrow"/>
              Back
            </button>
            <div v-if="verificationStep === 0" class="broker-login__switchers">
              <button
                :class="{ active: authType === 'broker' }"
                @click="setAuthType('broker')"
                class="btn"
              >
                {{ $t('web.agent') }}
              </button>
              <button
                :class="{ active: authType === 'agency' }"
                @click="setAuthType('agency')"
                class="btn"
              >
                {{ $t('web.agency') }}
              </button>
            </div>
            <AgentLoginForm/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import AgentLoginForm from '@/modules/broker/components/Login/AgentForm.vue'

export default {
  name: 'Login',
  components: {
    AgentLoginForm,
    Breadcrumb
  },
  props: {
    asComponent: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    setAuthType(type) {
      this.$store.commit('setAuthType', type)
    },
    toFirstStep() {
      this.$store.commit('setVerificationStep', 0)
    }
  },
  computed: {
    pages() {
      return {
        first: {
          title: this.$t('general.home'),
          srcName: 'MainRoute'
        },
        current: this.$t('web.pages_agent_login')
      }
    },
    authType() {
      return this.$store.state.auth.authType
    },
    verificationStep() {
      return this.$store.state.auth.verificationStep
    }
  }
}
</script>

<style lang="scss">
.broker-login {
  margin-bottom: 80px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__body {
    width: 100%;
    justify-content: center;
    display: flex;
    background-image: url('~@/assets/img/pages/broker-choice.webp');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    padding: 20px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &__form {
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    background: #fff;
    padding: 40px 60px;
    max-width: 460px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 479px) {
      border: none;
      padding: 20px 10px;
    }
  }

  &__switchers {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    & > button {
      border: 1px solid rgba(201, 157, 86, 1);
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: white;
      transition: all 0.2s ease;
      font-size: 16px;
      line-height: 24px;

      &.active {
        background: rgba(201, 157, 86, 1);
        color: white;
      }
    }

    @media screen and (max-width: 479px) {
      padding-bottom: 20px;
      gap: 10px;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    position: absolute;
    left: 20px;
    top: 20px;

    i {
      transform: rotate(-90deg);
      font-size: 8px;
      position: relative;
      top: 6px;
    }

    @media screen and (max-width: 479px) {
      left: 0;
      top: 10px;
    }
  }

  @media screen and (max-width: 479px) {
    margin-bottom: 0;
  }
}
</style>

<template>
	<div class="verification-inputs" v-click-away="removeFocus">
		<div
			v-for="n in length"
			:key="n"
			class="verification-inputs__wrapper"
			:class="{
				_empty: code[n-1] === ''
			}"
		>
			<VerificationInput
				ref="inputs"
        :value="code[n-1]"
        :focus="n - 1 === focus"
        :disabled="disabled"
        @keydown="(e) => handleInput(n - 1, e)"
        @focus="() => setFocus(n - 1)"
        @blur="() => removeFocus(n - 1)"
        @paste="(e) => handlePaste(n - 1, e)"
			/>
		</div>
	</div>
</template>

<script setup>
import {ref, computed, watch} from "vue";
import VerificationInput from "@/modules/broker/components/Verification/VerificationInput.vue";
import vClickAway from "@/directives/vClickAway";

const props = defineProps({
  modelValue: String,
  disabled: {
    type: Boolean,
    default: () => false
  },
})
const emit = defineEmits(['input', 'complete', 'update:modelValue']);
const length = 4
const pattern = /^[0-9]$/

let complete = ref(false);

const code = ref([]);
const codeToString = computed(() => {
  let str = '';
  for (let segment of code.value) {
    if (segment === '') {
      str += '_';
    } else {
      str += segment;
    }
  }
  return str;
});
const focus = ref(0);

function handleInput(pos, e) {
  switch (e.key) {
    case 'ArrowRight':
      if (pos < length - 1) focus.value = pos + 1;
      break;
    case 'ArrowLeft':
      if (pos > 0) focus.value = pos - 1;
      break;
    case 'Backspace':
      if (code.value[pos] !== '') {
        code.value[pos] = '';
      } else if (pos > 0) {
        code.value[pos - 1] = '';
        focus.value = pos - 1;
      }
      break;
    case 'Delete':
      code.value[pos] = '';
      break;
    default:
      if (pattern.test(e.key)) {
        code.value[pos] = e.key;
        if (pos < length - 1) {
          focus.value = pos + 1;
        } else {
          focus.value = null;
        }
      }
  }
}

function handlePaste(pos, e) {
  if (!e.clipboardData) return;

  const clipboardData = e.clipboardData.getData('text');
  const data = (clipboardData.match(/[0-9]/g) || []).join('');
  const newCode = [...code.value];
  let newFocusPosition;
  if (data.length === length) {
    for (let i = 0; i < data.length; i++) {
      newCode[i] = data[i];
    }
    newFocusPosition = null;
  } else {
    for (let i = 0; i < data.length && i + pos < newCode.length; i++) {
      newCode[i + pos] = data[i];
    }
    newFocusPosition = Math.min(pos + data.length, newCode.length);
  }
  code.value = newCode;
  focus.value = newFocusPosition;
}

function setFocus(pos) {
  focus.value = pos;
}

function removeFocus(pos) {
  if (focus.value === pos) focus.value = null;
}

_init();

function _init() {
  code.value = new Array(length);
  code.value.fill('');
  focus.value = 0;
}

watch(codeToString, (value) => {
  emit('update:modelValue', value);
  if (value.indexOf('_') === -1) {
    emit('complete', value);
    complete.value = true;
  }
});

watch(() => props.modelValue, (value) => {
  for (let i = 0; i < length; i++) {
    if (!value[i] || value[i] === '_') {
      code[i] = ''
    } else {
      code[i] = value[i]
    }
  }
})
</script>

<!--<script>-->
<!--import VerificationInput from '@/modules/broker/components/Verification/VerificationInput.vue'-->

<!--export default {-->
<!--	components: {-->
<!--		VerificationInput-->
<!--	},-->
<!--	props: {-->
<!--		length: {-->
<!--			type: Number,-->
<!--			default: 4-->
<!--		}-->
<!--	},-->
<!--	data() {-->
<!--		return {-->
<!--			codes: []-->
<!--		}-->
<!--	},-->
<!--	computed: {-->
<!--		code(){-->
<!--            return this.codes.join('')-->
<!--        }-->
<!--	},-->
<!--	methods: {-->
<!--		goOtherInput(direction, index, clear = false) {-->
<!--			if (direction === 'prev') {-->
<!--				const prevInput = this.$refs.inputs[index - 1]-->
<!--				if (prevInput) {-->
<!--					if (clear) {-->
<!--						prevInput.$el.value = ''-->
<!--						this.onDelete()-->
<!--					}-->
<!--					prevInput.focus()-->
<!--				}-->
<!--			} else {-->
<!--				const nextInput = this.$refs.inputs[index + 1]-->
<!--				if (nextInput) nextInput.focus()-->
<!--			}-->
<!--		},-->
<!--		onPaste(value) {-->
<!--			this.codes = value.split('').slice(0, this.length)-->
<!--			value.split('').forEach((num, index) => {-->
<!--				const input = this.$refs.inputs[index]-->
<!--				if (input) input.setValue(num)-->
<!--			})-->
<!--			this.$refs.inputs[this.codes.length - 1]?.focus()-->

<!--			this.updateCode()-->
<!--		},-->
<!--		updateCode() {-->
<!--			const { commit } = this.$store-->
<!--			commit('updateVerificationCode', this.code)-->
<!--		}-->
<!--	}-->
<!--}-->
<!--</script>-->

<style lang="scss" scoped>
.verification-inputs {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;

	&__wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__wrapper._empty {
		&::before {
			content: '';
			position: absolute;
			bottom: 10px;
			width: 14px;
			height: 2px;
			background-color: #000;
		}
	}
}
</style>

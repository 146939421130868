<template>
	<transition name="fade" mode="out-in">
		<section v-if="!loading" class="full-block">
			<div class="full-block__content" :class="config.class">
				<component :is="isMain ? 'h1' : 'h2'" class="full-block__hdng">
					<span v-html="config.title" />
				</component>
				<div class="full-block__text" v-html="config.text" />
				<div class="full-block__search-panel">
					<transition name="fade" mode="out-in">
						<suspense>
							<WiseSearchPanel
								type="home-page"
								v-if="!form"
								class="search-panel-home"
							/>
							<FormApplication v-else />
							<template #fallback>
								<or-skeleton :class="skeletonClass" />
							</template>
						</suspense>
					</transition>
				</div>
			</div>
		</section>
		<or-skeleton v-else class="full-block__skeleton container" />
	</transition>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import WiseSearchPanel from '@/components/SearchPanel/WiseSearchPanel.vue'

export default {
	components: {
		WiseSearchPanel,
		FormApplication: defineAsyncComponent(() =>
			import('./block/FormApplication')
		)
	},
	props: {
		isMain: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'guest'
		},
		form: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {}
	},
	computed: {
		config() {
			return this.type === 'guest'
				? {
						title: this.$t('home.titleGeneralBlock'),
						text: this.$t('home.textGeneralBlock'),
						class: 'full-block__content--guest'
				  }
				: {
						title: 'Leave a request and we will answer your questions',
						text: '',
						class: 'full-block__content--form'
				  }
		},
		isGuest() {
			return this.type === 'guest'
		},
		skeletonClass() {
			return {
				'full-block__skeleton--form': this.form,
				'full-block__skeleton--search': !this.form
			}
		}
	}
}
</script>

<style lang="scss">
.full-block {
	.container {
		@media (max-width: 768px) {
			padding: 0;
		}
	}
	&__content {
		padding: 9%;
		padding-bottom: 110px;
		border-radius: 4px;

		&--form {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
				url('~@/modules/home/assets/Form.jpeg'), #d9d9d9 no-repeat center;
			background-size: cover;
			text-align: center;

			@supports (background-image: url('~@/modules/home/assets/Form.webp')) {
				background: linear-gradient(
						0deg,
						rgba(0, 0, 0, 0.2),
						rgba(0, 0, 0, 0.2)
					),
					url('~@/modules/home/assets/Form.webp'), #d9d9d9 no-repeat center;
				background-size: cover;
			}

			@media (max-width: 600px) {
				background: linear-gradient(
						0deg,
						rgba(0, 0, 0, 0.2),
						rgba(0, 0, 0, 0.2)
					),
					url('~@/modules/home/assets/FormMobile.webp'),
					#d9d9d9 no-repeat center !important;
			}
		}

		&--guest {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
				url('~@/modules/home/assets/main-screen.jpeg') no-repeat center;
			background-size: cover;

			@supports (
				background-image: url('~@/modules/home/assets/main-screen.webp')
			) {
				background: linear-gradient(
						0deg,
						rgba(0, 0, 0, 0.3),
						rgba(0, 0, 0, 0.3)
					),
					url('~@/modules/home/assets/main-screen.webp') no-repeat center;
				background-size: cover;
			}

			@media (max-width: 600px) {
				background: linear-gradient(
						0deg,
						rgba(0, 0, 0, 0.3),
						rgba(0, 0, 0, 0.3)
					),
					url('~@/modules/home/assets/main-screenMobile.webp') no-repeat cover !important;
			}
		}

		@media screen and (max-width: 1050px) {
			padding: 5%;
			padding-bottom: 60px;
		}

		@media screen and (max-width: 800px) {
			padding: 40px;
		}

		@media screen and (max-width: 520px) {
			padding: 20px;
		}

		@media screen and (max-width: 470px) {
			padding: 20px 20px 60px 20px;
		}
	}

	&__hdng {
		font-weight: 600;
		font-size: 48px;
		line-height: 56px;
		color: #fff;
		margin-top: 0;
		margin-bottom: 15px;

		@media screen and (max-width: 450px) {
			font-weight: 600;
			font-size: 24px;
			line-height: 28px;
			text-align: center;
		}
	}

	&__text {
		margin-bottom: 55px;
		font-size: 20px;
		line-height: 23px;
		color: #fff;

		@media screen and (max-width: 500px) {
			font-weight: 400;
			font-size: 14px;
			line-height: 19px;
			text-align: center;
		}
	}

	&__send-ad {
		height: 58px;
		padding: 0 35px;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		background-color: #ffffff;
		border-radius: 40px;
		transition: all 0.5s linear;
		color: #2a3043;
		&:hover {
			background-color: #1ac386;
		}
	}
	&__skeleton {
		height: 628px;
		&--form {
			height: 108px;
		}
		&--search {
			height: 165px;
		}
		@media screen and (max-width: 1180px) {
			height: 967px;
			&--search {
				height: 525px;
			}
		}
		@media screen and (max-width: 1050px) {
			height: 792px;
		}
		@media screen and (max-width: 1024px) {
			height: 787px;
		}
		@media screen and (max-width: 912px) {
			height: 780px;
		}
		@media screen and (max-width: 800px) {
			height: 893px;
			&--form {
				height: 219px;
			}
		}
		@media screen and (max-width: 768px) {
			height: 840px;
		}
		@media screen and (max-width: 450px) {
			height: 743px;
		}
	}
}
</style>

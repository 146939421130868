<template>
  <main class="guest-page">
    <div class="container">
      <Full
        class="guest-page__item guest-page__item--full-block"
        type="guest"
        :is-main="true"
        :loading="loading"
      />

      <Gallery
        class="guest-page__item guest-page__item--district"
        :title="$t('home.titleAreas')"
        :content="infoAreas"
        :loading="loading"
        card="AreasCard"
        type-card="small"
        @cardClick="cardClickArea"
      />

      <Gallery
        class="guest-page__item guest-page__item--type"
        :title="$t('home.titleHousing')"
        card="HoundingTypeCard"
        :content="typeHousingList"
        @cardClick="cardClickHouseType"
        :loading="loading"
      />

      <!--		<Gallery
      class="guest-page__item"
      :title="$t('home.titleNews')"
      card="ArticleCard"
      :content="terms"
    />-->

      <Full
        class="guest-page__item guest-page__item--full-block"
        type="form"
        :form="true"
        :loading="loading"
      />

      <Login as-component/>
    </div>
  </main>
</template>

<script>
import {i18n} from '@/i18n'
import {setCommonMetaData} from '@/utils/setMetaData'
import {createRequest} from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import Full from '@/modules/home/components/FullBlock'
import Gallery from '@/components/Gallery'
import Login from "@/views/Broker/Login/Login.vue";

export default {
  name: 'Home',
  components: {
    Login,
    Full,
    Gallery
  },
  setup() {
    setCommonMetaData(`${i18n.global.t('titlePages.main')} - OWRealty`, {
      description: i18n.global.t('meta.homeDescription'),
      type: 'website',
      title: i18n.global.t('meta.homeTitle'),
      url: window.location.href
    })
  },
  data() {
    return {
      articles: {
        items: [],
        meta: {}
      },
      ideas: {
        items: [],
        meta: {}
      },
      loading: true,
      counters: {},

      typeHousingList: [],
      infoAreas: []
    }
  },
  methods: {
    cardClickArea(_, payload) {
      this.$router.push({
        name: 'SoloArea',
        params: {slug: payload.slug, lang: this.$store.state.uiLanguage}
      })
    },
    cardClickHouseType(_, payload) {
      this.$router.push({
        name: 'Search',
        query: {
          type_id: payload.id,
          property: payload.title
        },
        params: {lang: this.$store.state.uiLanguage}
      })
    }
  },
  beforeMount() {
    this.loading = true
    Promise.all([
      createRequest(requestConfigs.GETPlaceTypes, {}),
      createRequest(requestConfigs.GETRegionList, {
        queryPayload: {
          per_page: 24,
          page: 1,
          order_by: 'sort',
          direction: 'asc'
        }
      })
    ])
      .then((result) => {
        this.typeHousingList = result[0].response
        this.infoAreas.push(...result[1].response.items)
        this.meta = result[1].response.meta
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style lang="scss">
.guest-page {
  &__item {
    margin-bottom: 80px;

    &--full-block {
      margin-bottom: 85px;
    }

    &--type {
      margin-bottom: 55px;

      .skeleton-card {
        width: 380px;
      }
    }

    &--district {
      .skeleton-card {
        width: 280px;
      }

      .common-card__bg-image {
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 280px;
          max-height: 175px;
          height: 100%;
        }

        .skeleton-basic {
          width: 280px;
          height: 175px;
        }
      }
    }
  }
}
</style>

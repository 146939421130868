<template>
	<form
		@submit.prevent="submit"
		class="broker-form"
		v-if="verificationStep === 0"
	>
		<h1 class="broker-form__title">
			{{ authType === 'broker' ? $t('web.agent') : $t('web.agency') }}
			<span>
				{{ $t('web.login') }}
			</span>
		</h1>

		<!--			<div class="broker-form__social">-->
		<!--				<h2 class="broker-form__social-title">-->
		<!--					{{ $t('web.login_social_title') }}-->
		<!--				</h2>-->
		<!--				<ul class="broker-form__social-list">-->
		<!--					<li v-for="item in list" :key="item.icon">-->
		<!--						<a :href="item.link" class="broker-form__social-link">-->
		<!--							<img-->
		<!--								:src="require(`@/assets/img/svg/broker/${item.icon}.svg`)"-->
		<!--								:alt="item.icon"-->
		<!--								decoding="async"-->
		<!--							/>-->
		<!--						</a>-->
		<!--					</li>-->
		<!--				</ul>-->
		<!--			</div>-->
		<!--			<div class="broker-form__or">-->
		<!--				<span> {{ $t('web.or') }} </span>-->
		<!--			</div>-->

		<div class="broker-form__input">
			<div class="broker-form__input-item">
				<label for="auth-agent-email" class="broker-form__input-label">
					{{ $t('web.email') }}
				</label>
				<or-input
					required
					v-model="loginEmail"
					id="auth-agent-email"
					class="main-type"
					:placeholder="$t('web.email')"
					@input="clearError('email')"
					:error="errors.email"
				/>
			</div>
		</div>

		<or-button type="submit" class="broker-form__btn">
			<span>
				{{ $t('mobile.agent_get_code') }}
			</span>
		</or-button>
	</form>
	<VerificationCode v-else :key="verificationCodeTrials" />
</template>

<script>
import VerificationCode from '@/modules/broker/components/Verification/VerificationCode.vue'

export default {
	components: {
		VerificationCode
	},
	props: {
		type: {
			type: String,
			default: () => 'broker'
		}
	},
	data() {
		return {
			sentCode: false,
			form: {
				email: ''
			},
			errors: {}
		}
	},
	beforeMount() {
		Object.keys(this.form).forEach((key) => {
			// Set field errors
			this.errors[key] = { text: '', status: false }
		})
		this.$store.commit('resetVerification')
	},
	methods: {
		clearError(type) {
			this.errors[type].status = false
			this.errors[type].text = ''
		},
		submit() {
			this.$store.dispatch('authRequestCode').catch((err) => {
				if (Object.keys(err.errors).length) {
					Object.entries(err.errors).forEach(([key, value]) => {
						if (key === 'login') key = 'email'
						if (this.errors[key]) {
							this.errors[key].status = true
							this.errors[key].text = value.join(', ')
						}
					})
				}
			})
		}
	},
	computed: {
		list() {
			return [
				{
					icon: 'apple',
					link: ''
				},
				{
					icon: 'google',
					link: ''
				},
				{
					icon: 'facebook',
					link: ''
				},
				{
					icon: 'linked-in',
					link: ''
				}
			]
		},
		verificationCodeTrials() {
			return this.$store.state.broker.verificationCodeTrials
		},
		verificationStep() {
			return this.$store.state.auth.verificationStep
		},
		authType() {
			return this.$store.state.auth.authType
		},
		loginEmail: {
			get() {
				return this.$store.state.auth.authEmail
			},
			set(val) {
				this.$store.commit('setAuthEmail', val)
			}
		}
	}
}
</script>

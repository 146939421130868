<template>
	<div class="broker-form verification-code">
		<h1 class="broker-form__title" v-html="$t('web.agent_enter_code')"></h1>

		<VerificationInputs
			:length="4"
			@complete="login"
			:disabled="formDisabled"
		/>

		<or-button
			type="button"
			class="broker-form__btn"
			:disabled="resendTimeRemains > 0"
			@click="resendCode"
		>
			<span>
				{{ $t('mobile.agent_resend') }}
			</span>
		</or-button>
		<div class="verification-code__resend-timing">
			{{ resendTimeRemains > 0 ? $t('web.agent_send_code_again', { time: resendTimeRemains }) : '' }}
		</div>
	</div>
</template>

<script setup>
import VerificationInputs from '@/modules/broker/components/Verification/VerificationInputs.vue'
import { useStore } from 'vuex'
import { watch, ref, computed } from 'vue'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const resendTimeRemains = ref('0')
const formDisabled = computed(() => store.getters.codeVerificationDisabled)

function resendCode() {
	store.dispatch('authRequestCode')
}

async function login(code) {
	try {
		await store.dispatch('authLogin', code)
		await router.push({
			name: 'BrokerProfile',
			params: { lang: store.state.uiLanguage }
		})
	} catch (e) {}
}

let timer
function _startTimer() {
	const expires = store.state.auth.verificationCodeResend
	if (timer) clearInterval(timer)
  const msRemains = (expires * 1000 - Date.now()) / 1000
  resendTimeRemains.value = msRemains.toFixed(0)
	timer = setInterval(() => {
		const msRemains = (expires * 1000 - Date.now()) / 1000
		resendTimeRemains.value = msRemains.toFixed(0)
		if (msRemains <= 0) clearInterval(timer)
	}, 1000)
}

watch(
	() => store.state.auth.verificationCodeResend,
	() => {
		_startTimer()
	},
	{ immediate: true }
)
</script>

<style lang="scss" scoped>
.verification-code {
	@media screen and (max-width: 479px) {
		width: 100%;
	}
}
</style>

<template>
	<input
		class="verification-input"
		type="text"
		inputmode="numeric"
    ref="inputElement"
		maxlength="1"
    :disabled="disabled"
    :value="modelValue"
    @paste="handlePaste"
    @keydown="handleInput"
    @blur="emit('blur')"
    @focus="emit('focus')"
	/>
</template>

<script setup>
import {watch, onMounted, ref} from "vue";

const props = defineProps({
  modelValue: String,
  disabled: {
    type: Boolean,
    default: () => false
  },
  focus: {
    type: Boolean,
    required: false
  }
})

const emit = defineEmits(['keydown', 'paste', 'focus', 'blur']);

const inputElement = ref();

function handleInput(e) {
  if (e.metaKey || e.ctrlKey) return;

  e.preventDefault();
  emit('keydown', e);
}

function handlePaste(e) {
  e.preventDefault();

  emit('paste', e);
}

onMounted(() => {
  if (props.focus && inputElement.value) {
    inputElement.value.focus();
  }
});

watch(
  () => props.focus,
  (value) => {
    if (!inputElement.value) return;

    if (value === true) {
      inputElement.value.focus();
    } else {
      inputElement.value.blur();
    }
  }
);
</script>

<!--<script>-->
<!--export default {-->
<!--	props: {-->
<!--		modelValue: String,-->
<!--		index: Number-->
<!--	},-->
<!--	emits: ['update:modelValue', 'goOtherInput', 'paste'],-->
<!--	methods: {-->
<!--		goPrev(clear = false) {-->
<!--			this.$emit('goOtherInput', 'prev', this.index, clear)-->
<!--		},-->
<!--		goNext() {-->
<!--			this.$emit('goOtherInput', 'next', this.index)-->
<!--		},-->
<!--		onInput(event) {-->
<!--      event.preventDefault()-->
<!--      const value = event.target.value.replace(/\D/g, '')-->
<!--			this.$emit('update:modelValue', event.target.value.replace(/\D/g, ''))-->
<!--			if (event.target.value) this.goNext()-->
<!--		},-->
<!--		focus() {-->
<!--			this.$el.focus()-->
<!--		},-->
<!--		setValue(num) {-->
<!--			this.$el.value = num-->
<!--		},-->
<!--		onFocus() {-->
<!--			setTimeout(() => {-->
<!--				this.$el.selectionStart = this.$el.selectionEnd = this.$el.value.length-->
<!--			}, 0)-->
<!--		},-->
<!--		onKeydown(event) {-->
<!--			if (event.key.match(/backspace/i)) {-->
<!--				if (!event.target.value) this.goPrev()-->
<!--			} else if (event.key.match(/arrow/i)) {-->
<!--				if (event.key.match(/up|down/)) event.preventDefault()-->
<!--				else if (event.key.match(/left/i)) this.goPrev()-->
<!--				else if (event.key.match(/right/i)) this.goNext()-->
<!--			}-->
<!--		},-->
<!--		onPaste(event) {-->
<!--			const value = event.clipboardData.getData('text').replace(/\D/g, '')-->

<!--			this.$emit('paste', value)-->
<!--		}-->
<!--	},-->
<!--	mounted() {-->
<!--		if (this.index === 0) this.$el.focus()-->
<!--	}-->
<!--}-->
<!--</script>-->

<style lang="scss" scoped>
.verification-input {
	width: 40px;
	height: 52px;
	border-radius: 8px;
	border: 1px solid #cfcfcf;
	font-size: 32px;
	line-height: 130%;
	text-align: center;
}
</style>
